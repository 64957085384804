import Logo from '../../assets/logo.jpg'
import data from './data'
import { AiFillAppstore } from 'react-icons/ai'
import './navbar.css'

const Navbar = () => {

  return (
    <nav>
      <div className="container nav__container">
        <img className='nav__logo'  src={Logo} alt="Logo"/>
        <ul className='nav__menu'>
          {
            data.map(item => <li key={item.id}><a href={item.link}>{item.title}</a></li>)
          }
        </ul>
         <h3 id='theme__icon' ><AiFillAppstore /></h3>       
      </div>
    </nav>
  )
}

export default Navbar