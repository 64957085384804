import { AiFillHome } from "react-icons/ai";
import { BsPersonFill } from "react-icons/bs";
import { MdHomeRepairService } from "react-icons/md";
import { AiFillMessage } from "react-icons/ai";

const data = [
  { id: 1, link: "#", icon: <AiFillHome /> },
  { id: 2, link: "#sobremi", icon: <BsPersonFill /> },
  { id: 3, link: "#servicios", icon: <MdHomeRepairService /> },
  { id: 4, link: "#contacto", icon: <AiFillMessage /> },
];

export default data;
