import AboutImage from '../../assets/about.jpg'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="sobremi" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About" />
                </div>
            </div>
            <div className="about__right">
                <h2>Sobre mí</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                Soy ingeniero de software con amplia experiencia en el desarrollo, diseño y mantenimiento de sistemas complejos.
                </p>
                <p>
                He colaborado eficazmente con equipos de ingeniería remotos, enfocándome en los desafíos que enfrentan las grandes empresas en el ámbito del diseño, desarrollo y arquitectura de software.
                </p>
                <p>
                Tengo un gran interés en explorar nuevas tecnologías, especialmente aquellas relacionadas con la nube, y he adquirido un profundo conocimiento de muchas de ellas a lo largo de mi carrera.
                </p>
                <p>
                Mi vocación es estar siempre un paso adelante, anticipándome a las necesidades y cambios de la industria para ofrecer soluciones innovadoras.
                </p>
            </div>
        </div>
    </section>
  )
}

export default About