import { useEffect } from 'react'
import HeaderImage from '../../assets/header.jpg'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'

const Header = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])

  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__profile" data-aos="fade-in">
          <img src={HeaderImage} alt="Header Portait" />
        </div>
        <h3  data-aos="fade-up">Darwin Calma</h3>    
        <p  data-aos="fade-up">
        Ingeniero de Software
        </p>
        <div className="header__cta"  data-aos="fade-up">
          <a href="#contacto" className='btn primary'>Hablemos</a>
          <a href="#servicios" className='btn light'>Servicios</a>
        </div>
      </div>
    </header>
  )
}

export default Header