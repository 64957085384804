import { FaAward } from "react-icons/fa";
import { TbBooks } from "react-icons/tb";
import { BiWifi } from "react-icons/bi";

const data = [
  {
    id: 1,
    icon: <FaAward />,
    title: "Experiencia",
    desc: "profesional",
  },
  {
    id: 2,
    icon: <TbBooks />,
    title: "Proyectos",
    desc: " transformadores",
  },
  {
    id: 3,
    icon: <BiWifi />,
    title: "Trabajo",
    desc: "100% remoto",
  },
];

export default data;
