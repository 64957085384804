//import {links, socials} from './data'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <div className="footer__copyright">
        <small>Dcalma &copy; {1900 + new Date().getYear()}</small>
      </div>
    </footer>
  )
}

export default Footer